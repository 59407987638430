import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./screens/homepage";
import PrivacyPolicyPage from "./screens/privacy-policy";
import TermsAndConditionPage from "./screens/terms-and-conditions";
import RequestAccountDeletionPage from "./screens/account-deletion";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="terms-and-conditions"
          element={<TermsAndConditionPage />}
        />
        <Route
          path="request-account-deletion"
          element={<RequestAccountDeletionPage />}
        />
      </Routes>
    </Router>
  );
};

export default App;
