import logo from "../../assets/images/wonderkits-logo.svg";
import "../../App.css";
import DownloadButton from "../../components/download-button";
import { Typography, Box, Link as MaterialLink } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Homepage() {
  const navigate = useNavigate();

  const handleTermsAndConditions = () => {
    navigate("terms-and-conditions");
  };

  const handlePrivacyPolicy = () => {
    navigate("privacy-policy");
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo-box">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <DownloadButton />
        <MaterialLink
          href="https://api.whatsapp.com/send/?phone=60149863314&text&type=phone_number&app_absent=0"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontWeight: 700,
              color: "#7DC7C8",
              fontSize: 18,
              marginTop: 2,
            }}
          >
            {" "}
            Contact Us{" "}
          </Typography>
        </MaterialLink>
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
          <Typography
            sx={{
              paddingRight: 1,
              fontFamily: "Nunito",
              fontWeight: 700,
              color: "#F2BF00",
              fontSize: 18,
            }}
            onClick={handleTermsAndConditions}
          >
            {" "}
            Terms of Use{" "}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontWeight: 700,
              color: "#F2BF00",
              fontSize: 18,
            }}
            onClick={handlePrivacyPolicy}
          >
            {" "}
            Privacy Policy{" "}
          </Typography>
        </Box>
      </header>
      <footer className="footer-copyright"> © Copyright 2023 WonderKits</footer>
    </div>
  );
}

export default Homepage;
