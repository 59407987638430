import React from "react";
import googleplay from "../../assets/images/google-play-button.svg";
import appstore from "../../assets/images/app-store-button.svg";
import { Box } from "@mui/material";
import { Stack } from "@mui/system";

const DownloadButton = () => {
  return (
    <Box
      display="flex"
      sx={{
        my: 1.5,
        flexDirection: "row",
      }}
    >
      <Box sx={{ paddingRight: "5%" }}>
        <a
          href="https://play.google.com/store/apps/details?id=com.wonderkits.app"
          target="_blank"
          rel="noreferrer"
        >
          <img width="100%" src={googleplay} alt="Google Play" />
        </a>
      </Box>
      <a
        href="https://apps.apple.com/my/app/wonderkits/id6463154757"
        target="_blank"
        rel="noreferrer"
      >
        <img width="100%" src={appstore} alt="App Store" />
      </a>
    </Box>
  );
};

export default DownloadButton;
