import React from "react";
import { useState } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import Navbar from "../../components/navbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const RequestAccountDeletion = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    alert("Account deletion has been requested. You will be notified shortly");
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ m: { md: 1, xs: 2.5 }, pb: { sm: 0, xs: 2 } }}>
      <Container maxWidth="lg" sx={{ sm: "inherit", xs: "none" }}>
        <Navbar />
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: 24,
            fontWeight: 700,
            color: "#FFB02E",
          }}
        >
          Request Account Deletion
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            border: "1px solid gray",
            alignItems: "center",
            height: 38,
            borderRadius: 100,
            width: 270,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 15,
            marginBottom: 35,
          }}
        >
          <Typography
            sx={{ fontFamily: "Nunito", fontWeight: "400", fontSize: 16 }}
          >
            +60
          </Typography>
          <input
            type="tel"
            style={{
              fontFamily: "Nunito",
              fontWeight: "400",
              fontSize: 16,
              paddingLeft: 6,
              marginLeft: 10,
              border: "0px solid white",
            }}
            placeholder={"Enter phone number"}
            size="300px"
          />
        </div>
        <Box>
          <Button
            variant="contained"
            sx={{
              background: "#7DC7C8",
              borderRadius: 50,
              width: 130,
              "&:disabled": { backgroundColor: "#D4DFEC", color: "#fff" },
            }}
            onClick={handleClickOpen}
          >
            Next
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Request Account Deletion?"}
            </DialogTitle>
            <DialogContent>
              <Typography gutterBottom>
                By requesting account deletion, all of your data in WonderKits
                app will be removed. These data are:
              </Typography>
              <Typography gutterBottom>
                <ul>
                  <li>Name</li>
                  <li>Date of birth</li>
                  <li>Phone number</li>
                  <li>Email</li>
                  <li>Password (encrypted)</li>
                  <li>App activities</li>
                </ul>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleClose} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Box>
  );
};

export default RequestAccountDeletion;
