import React from "react";
import { Box, Text } from "@mui/material";
import logo from "../../assets/images/wonderkits.png";

const Navbar = () => {
  return (
    <Box sx={{ marginY: 2 }}>
      <img src={logo} width="170px" alt="wonderkits-logo" />
    </Box>
  );
};

export default Navbar;
