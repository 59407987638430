import { Box, Container, styled, Typography } from "@mui/material";
import "../../App.css";
import React from "react";
import Navbar from "../../components/navbar";

const SecondaryText = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: 18,
});

const TermsAndCondition = () => {
  return (
    <Box>
      <Container maxWidth="lg">
        <Navbar />
        <Box>
          <Typography
            sx={{
              fontSize: 24,
              fontFamily: "Nunito",
              fontWeight: 700,
              color: "#7DC7C8",
            }}
          >
            {" "}
            Terms & Conditions{" "}
          </Typography>
        </Box>
        <Box>
          <SecondaryText>
            <p>Last updated: August 24, 2023</p>
            <p>
              Welcome to WonderKits! These terms and conditions outline the
              rules and regulations for the use of our app.
            </p>
            <p>
              By accessing this app, we assume you accept these terms and
              conditions. Do not continue to use WonderKits if you do not agree
              to all of the terms and conditions stated on this page.
            </p>
            <p>The following terminology applies to these Terms of Use:</p>
            <p>
              "User," "You," and "Your" refers to you, the person accessing this
              app and accepting these terms. "We," "Our," and "Us" refers to
              WonderKits and its affiliates. License: Unless otherwise stated,
              WonderKits and/or its licensors own the intellectual property
              rights for all material on the app. All intellectual property
              rights are reserved. You may access this from WonderKits for your
              personal use subject to restrictions set in these terms.
            </p>
            <p>You must not:</p>
            <p>
              Republish material from WonderKits
              <br />
              Sell, rent, or sub-license material from WonderKits
              <br />
              Reproduce, duplicate, or copy material from WonderKits
              <br />
              Redistribute content from WonderKits
              <br />
              User Content: By using our app, you grant us permission to use and
              share the content you provide, such as comments, feedback, or
              suggestions. You are solely responsible for your content and its
              accuracy.
            </p>
            <p>
              Restrictions:
              <br /> You are specifically restricted from all of the following:
            </p>
            <p>
              Using the app in any way that impacts user access to it
              <br />
              Using the app contrary to applicable laws and regulations, or in
              any way that may cause harm to the app, or to any person or
              business entity
              <br />
              Engaging in any data mining, data harvesting, data extracting, or
              any other similar activity in relation to the app
              <br />
            </p>
            <p>
              No warranties:
              <br />
              This app is provided "as is," with all faults, and WonderKits
              makes no express or implied representations or warranties, of any
              kind related to this app or the materials contained on it.
            </p>
            <p>
              Limitation of liability:
              <br />
              In no event shall WonderKits, nor any of its officers, directors,
              and employees, be liable to you for anything arising out of or in
              any way connected with your use of this app, whether such
              liability is under contract, tort, or otherwise.
            </p>
            <p>
              Indemnification:
              <br />
              You hereby indemnify to the fullest extent WonderKits from and
              against any and/or all liabilities, costs, demands, causes of
              action, damages, and expenses arising in any way related to your
              breach of any of the provisions of these terms.
            </p>
            <p>
              Severability:
              <br />
              If any provision of these terms is found to be invalid under any
              applicable law, such provisions shall be deleted without affecting
              the remaining provisions herein.
            </p>
            <p>
              Variation of Terms:
              <br />
              WonderKits is permitted to revise these terms at any time as it
              sees fit, and by using this app you are expected to review these
              terms on a regular basis.
            </p>
            <p>
              Entire Agreement:
              <br />
              These terms constitute the entire agreement between WonderKits and
              you in relation to your use of this app and supersede all prior
              agreements and understandings.
            </p>
            <p>
              Governing Law & Jurisdiction:
              <br />
              These terms will be governed by and interpreted in accordance with
              the laws of Malaysia, and you submit to the non-exclusive
              jurisdiction of the state and federal courts located in Kuala
              Lumpur, Malaysia for the resolution of any disputes.
            </p>
            <p>
              Contact Us
              <br />
              If you have any questions about this Terms & Conditions, You can
              contact us:
            </p>
            <p>By visiting this page on our website: https://www.cikguu.com</p>
            <p>By phone number: +60149863314 </p>
          </SecondaryText>
        </Box>
      </Container>
      <footer className="footer-copyright"> © Copyright 2023 WonderKits</footer>
    </Box>
  );
};

export default TermsAndCondition;
