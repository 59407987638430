import { Box, Container, Grid, styled, Typography } from "@mui/material";
import "../../App.css";
import React from "react";
import Navbar from "../../components/navbar";

const SecondaryText = styled(Typography)({
  fontFamily: "Nunito",
  fontSize: 18,
});

const PrivacyPolicy = () => {
  return (
    <Box>
      <Container maxWidth="lg">
        <Navbar />
        <Box>
          <Typography
            sx={{
              fontSize: 24,
              fontFamily: "Nunito",
              fontWeight: 700,
              color: "#7DC7C8",
            }}
          >
            {" "}
            Privacy Policy{" "}
          </Typography>
        </Box>
        <Box>
          <SecondaryText>
            <p>Last updated: August 24, 2023</p>
            <p>
              At WonderKits, we value your privacy and are committed to
              protecting your personal information. This Privacy Policy outlines
              how we collect, use, disclose, and safeguard your data when you
              use our app.
            </p>
            <p>Information We Collect:</p>
            <p>
              Personal Information: When you create an account or use our
              services, we may collect personal information such as your name,
              email address, and other relevant information.
            </p>
            <p>
              Usage Data: We may collect information about how you use our app,
              including your interactions with content, features, and other
              users.
            </p>
            <p>
              How We Use Your Information: To Provide Services: We use your
              information to deliver the services you request, such as sending
              you daily lessons and content.
            </p>
            <p>
              Personalization: We may use your data to personalize your learning
              experience, recommend relevant content, and improve the app.{" "}
            </p>
            <p>
              Communication: We may send you notifications, updates, and
              promotional materials. You can opt out of these communications at
              any time.
            </p>
            <p>
              Disclosure of Your Information: We may share your information with
              trusted third-party service providers who assist us in delivering
              our services.
            </p>
            <p>
              In response to Legal Requirements: We may disclose your
              information to comply with legal obligations or respond to lawful
              requests.
            </p>
            <p>
              Data Security: We implement security measures to protect your data
              from unauthorized access and maintain its accuracy.
            </p>
            <p>
              Your Choices: You can access and update your personal information
              in your account settings. You can opt out of promotional
              communications by following the instructions provided in the
              communication.
            </p>
            <p>
              Children's Privacy: WonderKits is intended for users of all ages,
              but parental consent may be required for users under the age of
              13, as per applicable regulations. Changes to this Privacy Policy:
            </p>
            <p>
              We may update this Privacy Policy as needed. The revised version
              will be posted on our app.
            </p>
            <p>
              Contact Us
              <br />
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <p>By visiting this page on our website: https://www.cikguu.com</p>
            <p>By phone number: +60149863314 </p>
          </SecondaryText>
        </Box>
      </Container>
      <footer className="footer-copyright"> © Copyright 2023 WonderKits</footer>
    </Box>
  );
};

export default PrivacyPolicy;
